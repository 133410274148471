import * as React from "react"
import Layout from "../../components/layout"

export default () => (
    <Layout title={"Julekort 2020"}>
        <h1>God Jul ❤️</h1>
        <div>Under følger årets julepodcast!</div>
        <div>
            <audio controls>
                <source src="https://drive.google.com/uc?export=download&id=1smhgROPHxJRAk7AB6sc2c8M-lMtIudru"
                        type="audio/mpeg"/>
                Your browser does not support the audio element.
            </audio>
            <div>
                <a href="https://drive.google.com/uc?export=download&id=1smhgROPHxJRAk7AB6sc2c8M-lMtIudru">Last
                    ned</a></div>
        </div>
    </Layout>
)
